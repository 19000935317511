<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card :isloading="cardloading">
        <template slot="body">
          <form-validation @submit="submitForm">
            <b-row>
              <b-col sm="12" md="8">
                <form-input :rules="{required: true}" v-model="name" label="Nama Komisi Mitra"></form-input>
              </b-col>
              <b-col sm="12">
                <form-select ref="mitraSelect" :rules="{required: true}" url="v1/payment_partner_select2" v-model="id_payment_partner" label="Mitra"></form-select>
              </b-col>
              <b-col sm="12" v-show="!all_region">
                <form-select-multiple ref="regionalSelect" :rules="{required: !all_region && !id_branches.length}" url="v1/regional_select2" v-model="id_regionals" label="Regional"></form-select-multiple>
              </b-col>
              <b-col sm="12" v-show="!all_region">
                <form-select-multiple ref="branchSelect" :rules="{required: !all_region && !id_regionals.length}" :url="`v1/branch_select2?id_regional=${idRegionalToString}`" v-model="id_branches" label="Cabang"></form-select-multiple>
              </b-col>
              <b-col sm="12" md="8">
                <form-checkbox label="Semua Regional dan Cabang" v-model="all_region"></form-checkbox>
              </b-col>
              <b-col sm="12" md="5">
                <form-input-number :rules="{required: true}" v-model="percentage" label="Persentase"></form-input-number>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: false}" v-model="note" label="Keterangan"></form-input>
              </b-col>
              <b-col sm="12" class="mt-3">
                <button-save :isloading="isloading"></button-save>
                <button-back class="ml-1" :to="{name:'commissions'}"></button-back>
              </b-col>
            </b-row>
          </form-validation>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from '@/my-components/FormInput.vue'
import FormInputNumber from '@/my-components/FormInputNumber.vue'
import FormSelect from '@/my-components/FormSelect.vue'
import FormSelectMultiple from '@/my-components/FormSelectMultiple.vue'
import FormCheckbox from '@/my-components/FormCheckbox.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'
import _ from 'lodash'

export default {
  components:{
    FormInput,
    ButtonSave,
    ButtonBack,
    FormValidation,
    FormCheckbox,
    FormSelect,
    FormSelectMultiple,
    FormInputNumber
  },
  data(){
    return {
      id: this.$route.params.id||null,
      id_payment_partner:null,
      name:'',
      note:'',
      all_region:false,
      percentage:0,
      id_regionals:[],
      id_branches:[],
      is_active: true,
      isloading: false,
      cardloading: false
    }
  },
  computed:{
    idRegionalToString(){
      return _.toString(this.id_regionals)
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'commissions/store',
      dispatchShow: 'commissions/show',
      dispatchUpdate: 'commissions/update',
    }),
    cityChange(json){
      if(json.label) this.name_city = json.label;
      else this.name_city = '';
    },
    async getData(){
      this.cardloading = true
      const data = await this.dispatchShow(this.id)
      this.name = data.name
      this.percentage = data.percentage
      this.id_payment_partner = data.id_payment_partner
      this.all_region = data.all_region ? true : false
      this.is_active = data.is_active ? true : false
      this.note = data.note
      if (this.id_payment_partner) {
        const {name} = data.contact
        this.$refs.mitraSelect.initOption([{"value":data.id_payment_partner,"label":name}])
      }
      let selectedRegional = []
      let selectedBranch = []
      for (let i = 0; i < data.payment_partner_commiss_regionals.length; i++) {
        const e = data.payment_partner_commiss_regionals[i];
        if(e.id_regional) {
          selectedRegional.push(e.id_regional)
          this.$refs.regionalSelect.initOption([{"value":e.id_regional,"label":e.regional.name}])
        }
        if(e.id_branch) {
          selectedBranch.push(e.id_branch)
          this.$refs.branchSelect.initOption([{"value":e.id_branch,"label":e.branch.name}])
        }
      }
      this.id_regionals = _.uniq(selectedRegional)
      this.id_branches = _.uniq(selectedBranch)
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,id_payment_partner,name,note,id_regionals,id_branches,percentage} = this
      let params = {id,id_payment_partner,name,note,id_regionals,id_branches,percentage}
      params.is_active = this.is_active ? 1 : 0
      params.all_region = this.all_region ? 1 : 0
      if (this.all_region) {
        params.id_regionals = []
        params.id_branches = []
      }
      try {
        if (this.id) await this.dispatchUpdate(params);
        else await this.dispatchStore(params);
        this.toastSuccess("OK","Data berhasil disimpan!")
        this.$router.push({name:'commissions'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
  },
  mounted(){
    if(this.id) return this.getData();
  }
}
</script>

<style>

</style>